import React, {
  createContext,
  useContext,
  ReactNode,
  FC,
  useReducer,
  useMemo,
  useCallback,
  PropsWithChildren
} from 'react'

import map from 'lodash/map'

import {
  CREATE_MODAL,
  CLOSE_MODAL,
  REMOVE_MODAL,
  RESET_MODALS,
  modalsReducer,
  Modal,
  RemoveModal
} from './modalsReducer'

interface Props {
  ContainerComponent?: React.ElementType;
}

interface Values {
  createModal: (options: Modal) => void;
  removeModal: (options: RemoveModal) => void;
  resetModals: () => void;
  modals: Modal[];
}

const ModalsContext = createContext<Values>({} as Values)
export const useModal = (): Values => useContext<Values>(ModalsContext)

const GenericComponent: FC<PropsWithChildren<any>> = ({ children }) => children || null

const ModalsProvider: FC<PropsWithChildren<Props>> = (props) => {
  const {
    ContainerComponent = GenericComponent,
    children
  } = props

  const [modals, dispatch] = useReducer(modalsReducer, [])

  const createModal = useCallback(
    (modal: Modal): void => dispatch({ type: CREATE_MODAL, modal }),
    []
  )

  const removeModal = useCallback(
    (modal: RemoveModal, immediately = false): void => {
      if (immediately) {
        dispatch({ type: REMOVE_MODAL, modal })
      } else {
        dispatch({ type: CLOSE_MODAL, modal })
        setTimeout(() => {
          dispatch({ type: REMOVE_MODAL, modal })
        }, 300)
      }
    },
    []
  )

  const resetModals = useCallback(
    (): void => dispatch({ type: RESET_MODALS }),
    []
  )

  const state = useMemo<Values>(
    () => ({
      createModal,
      removeModal,
      resetModals,
      modals
    }),
    [createModal, modals, removeModal, resetModals]
  )

  const renderedModals = useMemo<ReactNode[]>(
    () => map(modals, (modal) => {
      const {
        id,
        open,
        Component,
        props: modalProps = {}
      } = modal

      return (
        <Component
          key={ id }
          id={ id }
          handleClose={ (): void => removeModal({ id }) }
          open={ open }
          { ...modalProps }
        />
      )
    }),
    [modals, removeModal]
  )

  return (
    <ModalsContext.Provider value={ state }>
      {children}

      <ContainerComponent>
        {renderedModals}
      </ContainerComponent>
    </ModalsContext.Provider>
  )
}

export default ModalsProvider
